

export const NOT_FOUND = '/404'

export const { APP_ROOT_URL } = process.env;


//sidebar
export const SITE_ADMIN = '/site-admin';
export const ADMIN_DASHBOARD = SITE_ADMIN + '/';
export const ADMIN_LOGOUT = SITE_ADMIN + '/logout';
export const ADMIN_COMMENT = SITE_ADMIN + '/comment';
export const ADMIN_AUTHOR = SITE_ADMIN + '/author'
export const ADMIN_PAGE = SITE_ADMIN + '/page';
export const ADMIN_BLOG = SITE_ADMIN + '/blog';
export const ADMIN_BLOG_SLUG = SITE_ADMIN + '/blog/[create]';
export const ADMIN_MEDIA = SITE_ADMIN + '/media';

//author
export const ADMIN_AUTHOR_CREATE = SITE_ADMIN + '/author/[create]'

// organic keywords 
export const ORGANIC_KEYWORDS = SITE_ADMIN + '/organic-keywords'

//pages
export const ADMIN_PAGE_STATIC = SITE_ADMIN + '/page/static'
export const ADMIN_PAGE_RESUMES = SITE_ADMIN + '/page/resumes'
export const ADMIN_PAGE_JOBS = SITE_ADMIN + '/page/jobs';
export const ADMIN_JOBS_CATEGORY = SITE_ADMIN + '/page/category';
export const ADMIN_JOBS_SKILL = SITE_ADMIN + '/page/skill';
export const ADMIN_STATIC_SLUG = SITE_ADMIN + '/page/static/[create]'
export const ADMIN_STATIC_CREATE = SITE_ADMIN + '/page/static/create'
export const ADMIN_JOB_SLUG = SITE_ADMIN + '/page/jobs/[create]'
export const ADMIN_JOB_CREATE = SITE_ADMIN + '/page/jobs/create'
export const ADMIN_RESUME_SLUG = SITE_ADMIN + '/page/resumes/[create]'
export const ADMIN_RESUME_CREATE = SITE_ADMIN + '/page/resumes/create'

// dynamic slider 
export const ADMIN_DYNAMIC_SLIDER = SITE_ADMIN + '/page/dynamic-slider';
export const ADMIN_DYNAMIC_SLIDER_CREATE = SITE_ADMIN + '/page/dynamic-slider/create';

// enterprise deals 
export const ADMIN_ENTERPRISE_DEALS = SITE_ADMIN + '/page/enterprise-deals';
export const ADMIN_UPDATE_CURRENCY = SITE_ADMIN + '/page/currency';


// analytics 
export const ADMIN_MARKETING_ANALYTICS = SITE_ADMIN + '/marketing-dashboard';
export const ADMIN_USER_LOGS = SITE_ADMIN + '/user-logs';
export const ADMIN_SOURCE_DASHBOARD = SITE_ADMIN + '/source-dashboard';
export const ADMIN_ERROR_LOGS = SITE_ADMIN + '/error-logs';
//blog
export const ADMIN_BLOG_VIEW = SITE_ADMIN + '/blog';
export const ADMIN_BLOG_CREATE = SITE_ADMIN + '/blog/create';
export const ADMIN_BLOG_TYPE = SITE_ADMIN + '/blog/type';
export const ADMIN_BLOG_TYPE_CREATE = SITE_ADMIN + '/blog/type/create';
export const ADMIN_BLOG_TYPE_SLUG = SITE_ADMIN + '/blog/type/[create]';
export const ADMIN_BLOG_CATEGORY = SITE_ADMIN + '/blog/category';
export const ADMIN_BLOG_CATEGORY_CREATE = SITE_ADMIN + '/blog/category/create';
export const ADMIN_BLOG_CATEGORY_SLUG = SITE_ADMIN + '/blog/category/[create]';
export const ADMIN_BLOG_TAG = SITE_ADMIN + '/blog/tags';
export const ADMIN_BLOG_AUTHOR = SITE_ADMIN + '/blog/author';

//helper
export const ADMIN_HELPER_CAT = SITE_ADMIN + '/help/category'
export const ADMIN_HELPER_TOPICS = SITE_ADMIN + '/help/topics'
export const ADMIN_HELPER_ARTICLES = SITE_ADMIN + '/help/articles'
export const ADMIN_HELPER_ARTICLES_CREATE = ADMIN_HELPER_ARTICLES + '/create'


//setting
export const ADMIN_SETTING_INJECT = SITE_ADMIN + '/setting/injector'


//website
export const HOMEPAGE = '/';
export const FIND_JOBS = '/find-jobs';
export const FIND_STAFF = '/find-staff';
export const ENTERPRISE = '/enterprise';
export const CASE_STUDIES = '/enterprise/case-studies';
export const ABOUT_US = '/about-us';
export const BLOG = '/blog';
export const HELPFULL_RESOURCES = '/helpful-resources';
export const CONTACT_US = '/contact-us';
export const PRIVACY = '/privacy-policy';
export const TERMS_AND_USE = '/terms-and-use';
export const HOW_IT_WORKS_EMPLOYERS = '/how-it-works-employer';
export const HOW_IT_WORKS_JOB_SEEKERS = '/how-it-works-jobseeker';
export const SEE_ALL_SPECIALIZATION = '/see-all-specialization';
export const JOB_DESCRIPTION = '/job-description';
export const APPLICANT_PROFILE = '/applicant-profile';
export const JOB_SEEKER_INDIVIDUAL = '/jobseeker';
export const HOW_WORK_FROM_HOME = '/jobseeker-education'
export const JOBS_IN_PHILIPPINES = '/jobs-in-philippines'

export const WEBSITE_HELP_CENTER = 'https://support.virtualstaff.ph'

//login
export const LOGIN = `${APP_ROOT_URL}/login`;
export const SIGNUP = `${APP_ROOT_URL}/signup`;
export const SIGN_UP_EMPLOYER = `${APP_ROOT_URL}/signupemployer`;
export const SIGN_UP_JOB_SEEKER = `${APP_ROOT_URL}/signupjobseeker`;
export const SITE_ADMIN_FORGET = `${APP_ROOT_URL}/forgotpassword?type=admin`
export const PRICING_PAGE = '/subscription-pricing';
export const PROTECTION_PAGE = '/employer-protection';
export const BUSINESS_PLAN_PROTECTION = '/business-protection-plan';
export const AI_TALENT = '/ai-talent-match'
export const AFFILIATE_APP = 'https://affiliates.virtualstaff.ph/'

export const AFFILIATE_HOW_IT_WORKS = 'https://affiliates.virtualstaff.ph/#/how-it-works-affiliate'
