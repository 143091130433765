import store from '../redux/store.js';
import moment from 'moment';
import { DEFAULT_TOKEN, JOB_TYPE, TOASTR_OPTIONS, USER_DATA, S3_BUCKET_URL, IMAGE_API } from '../config/constant/projectConstant.js';
import { sessionFail } from '../redux/slicers/sessionSlice.js';
import { convertToHTML, parseHTML, spaceReduce, spaceReduceSmall } from './jsHelper';
import swal from 'sweetalert';
import { LOGIN } from "../config/constant/routeConstant";
import { USER_IMG } from "../config/constant/imgConstant";
import { htmlDecode } from '../components/site-pages/siteBasic.js';

export const devConsoleLog = (...a) => {
    if (a) {
        if (isDev()) {
            console.log(...a);
        }
    }
};
export const dummyQueryUser = () => {
    const { user_id = null } = getUserData();
    const formQuery = {
        query: {
            user_id,
        },
    };
    return formQuery;
};

export const jobUrlCreate = (a) => {
    const title = spaceReduceSmall(a?.job_title || '');
    const name = spaceReduceSmall(a?.job_name || '');
    const second = title ? title : name
    let url = [a._id, second].join('/');
    url = url.replace(/--/g, '')
    return url
}

export const isDev = () => {
    return process.env.NODE_ENV === 'development';
};

export const dispatcher = (a) => {
    store.dispatch(a);
};
export const menuIdUpdate = (arr) => {
    return arr.map(a => ({ ...a, id: spaceReduce(a?.name), subMenu: a?.subMenu ? menuIdUpdate(a.subMenu) : null }))
}
export const menuReverseDetect = (menu, pathname) => {
    return menu.reduce((acc, a) => {
        let name = null;
        let subName = null;
        if (a.path == pathname) {
            acc.name = a.name;
        }
        if (a.subMenu) {
            const list = a.subMenu.find(i => i.path == pathname);
            if (list?.name) {
                acc.subName = list.name;
                acc.name = a.name;
            }
        }
        return acc
    }, {})
}
export const storeLocal = (data = '', tokenName = DEFAULT_TOKEN) => {
    if (typeof data === 'object') {
        data = JSON.stringify(data);
    }
    window.localStorage.setItem(tokenName, data);
};
export const getLocal = (tokenName = DEFAULT_TOKEN) => {
    const localData = window.localStorage.getItem(tokenName);
    let res;
    try {
        res = JSON.parse(localData);
    } catch (err) {
        res = localData;
    }
    return res;
};
export const getUserData = (userData = USER_DATA) => {
    const localData = window.localStorage.getItem(userData);
    let res;
    try {
        res = JSON.parse(localData);
    } catch (err) {
        res = localData;
    }
    return res;
};
export const removeLocal = (tokenName = DEFAULT_TOKEN) => {
    window.localStorage.removeItem(tokenName);
};

export const sessionDestroy = (path = LOGIN) => {
    removeLocal();
    removeLocal(USER_DATA);
    dispatcher(sessionFail());
    // navigate(path);
};

export const uploadPathBuilder = (root, a) => {
    return root + a;
};

export const navigate = (path) => {
    // dispatcher(navigatePath(path));
};

export const ageCalc = (d) => {
    return moment(new Date()).diff(moment(d), 'years');
};

export const confirmAlert = ({ title, msg, success = null, cancel = null }) => {
    return swal({ title, text: msg, icon: "warning", buttons: true, dangerMode: true, }).then((confirm) => {
        return confirm ? success?.() : cancel?.()
    });
}
export const successToast = ({ title, msg, toastrOptions = TOASTR_OPTIONS }) => {
    return swal({ title, text: msg, icon: "success", });
};

export const errorToast = ({ title, msg, toastrOptions = TOASTR_OPTIONS }) => {
    return swal({ title, text: msg, icon: "error", });
};

export const infoToast = ({ title, msg, toastrOptions = TOASTR_OPTIONS }) => {
    return swal({ title, text: msg, icon: "info", });
};

export const warningToast = ({ title, msg, toastrOptions = TOASTR_OPTIONS }) => {
    return swal({ title, text: msg, icon: "warning", });
};

export const validate = (fieldName, value, value2) => {
    let valid;
    let error;
    let errorTwo;
    let validData = {};
    switch (fieldName) {
        case 'full_name':
            valid = value.length >= 5;
            error = valid ? '' : 'Minimum 5 characters length required';
            validData = {
                valid,
                error,
            };
            return validData;
        case 'user_email':
            valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/);
            error = valid ? '' : 'Please enter valid email address.';
            validData = {
                valid,
                error,
            };
            return validData;
        case 'password':
        case 'new_password':
            valid =
                value.length >= 8 &&
                value.match(/(?=.*[_!@#$%^&*-])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])^.{8,}$/);
            error = valid ? (
                ''
            ) : (
                <div>
                    {/[A-Z]/.test(value) === false && <li>must contain at least one uppercase letter</li>}
                    {/[a-z]/.test(value) === false && <li>one lowercase letter</li>}
                    {/[0-9]/.test(value) === false && <li>minimum one number</li>}
                    {value.length < 8 && <li>one special character and a minimum of 8 characters</li>}
                    {/[_!@#$%^&*-]/.test(value) === false && <li>minimum one special character </li>}
                </div>
            );

            validData = {
                valid,
                error,
                errorTwo,
            };
            return validData;
        default:
            return validData;
    }
};

export const errorValidator = (a, setState) => {
    const { name, value = '' } = a;
    if (value.trim()) {
        const { error = null } = validate(name, value);
        setState((err) => {
            return { ...err, [name]: error };
        });
    } else {
        setState((err) => {
            return { ...err, [name]: null };
        });
    }
};

export const createShortString = (a = null) => {
    const str = a ? parseHTML(convertToHTML(a)) : '';
    return str
}

export const createShortStringCK = (a = null, limit = 100) => {
    const str = a ? parseHTML(a) : '';
    return str.substring(0, limit)
}

export const imageShow = (a = null) => a ? `${IMAGE_API_ROOT}${a}` : USER_IMG;
export const jobType = (type = '') => JOB_TYPE.find(a => a.value === type)?.['text'] ?? null


export const HtmlShortString = (input = null) => {
    return <>
            <div dangerouslySetInnerHTML={{ __html: input.concat('...') }} /><u style={{color:"#004C70"}}>Know more</u>
          </>
}

export const HtmlConverter = ({ input = null }) => {
    let data = <div dangerouslySetInnerHTML={{ __html: htmlDecode(input) }} />
    let check = data?.props?.dangerouslySetInnerHTML?.__html
    return check.includes('color') ? <div className="default-css" dangerouslySetInnerHTML={{ __html: htmlDecode(input.slice(0, 1000).concat("...")) }} /> : <div className="format-desc default-css" dangerouslySetInnerHTML={{ __html: htmlDecode(input.slice(0, 100).concat('...')) }} />
}
export const HtmlParser = (input = '') => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlDecode(input), 'text/html');
    return htmlDoc.body.textContent.slice(0,150) 
}

